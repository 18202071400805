import * as React from 'react';

const Icon = props => {
  const { color = '#1A51A4' } = props;
  return (
    <svg width={11} height={1} viewBox="0 0 11 1" {...props}>
      <defs>
        <path id="prefix__minusIcon" d="M0 0h11v1H0z" />
      </defs>
      <use fill={color} fillRule="nonzero" xlinkHref="#prefix__minusIcon" />
    </svg>
  );
};

export default Icon;
