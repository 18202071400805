import React, { useState } from 'react';
import Layout from 'components/Layout';
import SEO from 'components/seo';
import Button from 'components/Button';
import PlusIcon from 'svg/PlusIcon';
import MinusIcon from 'svg/MinusIcon';
import Auctions from 'features/catalogue/auctions';
import { UPCOMING_DATA, PAST_DATA } from 'src/data/catalogue';
import styles from './catalogue.module.scss';

const blueColor = '#1a51a4';
const minimumQuantity = 1;

const Catalogue = () => {
  const [quantity, setQuantity] = useState(minimumQuantity);

  const updateQuantity = add => {
    if (!add && quantity === minimumQuantity) {
      return;
    }

    setQuantity(add ? quantity + 1 : quantity - 1);
  };

  return (
    <Layout>
      <SEO title="Catalogue" />
      <div className={styles.catalogueSection}>
        <div className={styles.headingSection}>
          <div>
            <h1>ORDER A CATALOGUE</h1>
            <Button type="">View Shopping Cart</Button>
          </div>
        </div>
        <div className={styles.subscriptionSection}>
          <div>
            <h2>1-Year Catalogue Subscription</h2>
            <p>
              Order your 1-year subscription to receive a catalogue from each of
              our annual auctions including Scottsdale, Amelia Island and Pebble
              Beach. Please Note: Your subscription will begin with the next
              upcoming auction catalogue. Your order will not automatically
              renew at the end of your subscription.
            </p>
            <p>
              Please note: Upcoming auction catalogues will ship approximately
              three weeks prior to the auction date.
            </p>
          </div>
          <div className={styles.cartSection}>
            <div>
              <h4>$230.00</h4>
              <div className={styles.count}>
                <Button
                  type={styles.iconButton}
                  handleClick={() => updateQuantity(false)}
                >
                  <MinusIcon color={blueColor} />
                </Button>
                <h4>{quantity}</h4>
                <Button
                  type={styles.iconButton}
                  handleClick={() => updateQuantity(true)}
                >
                  <PlusIcon color={blueColor} />
                </Button>
              </div>
            </div>
            <Button type="app-primary-button">Add to Cart</Button>
          </div>
        </div>
        <div>
          <Auctions
            upcoming={UPCOMING_DATA}
            past={PAST_DATA}
            minimumQuantity={minimumQuantity}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Catalogue;
