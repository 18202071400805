import BannerImage from 'images/img-catalogue-bg.png';

const UPCOMING_DATA = [
  {
    title: 'Amelia Island 2018',
    price: '40.00',
    quantity: 1,
    image: BannerImage,
  },
  {
    title: 'Amelia Island 2019',
    price: '40.00',
    quantity: 1,
    image: BannerImage,
  },
  {
    title: 'Amelia Island 2020',
    price: '40.00',
    quantity: 1,
    image: BannerImage,
  },
];

const PAST_DATA = [
  {
    title: 'Amelia Island 2018',
    price: '40.00',
    quantity: 1,
    image: BannerImage,
  },
  {
    title: 'Amelia Island 2019',
    price: '40.00',
    quantity: 1,
    image: BannerImage,
  },
];

export { UPCOMING_DATA, PAST_DATA };
