import React, { useState } from 'react';
import Filters from '../filters';
import Button from 'components/Button';
import PlusIcon from 'svg/PlusIcon';
import MinusIcon from 'svg/MinusIcon';
import styles from './auctions.module.scss';

const Auctions = ({ upcoming, past, minimumQuantity }) => {
  const [selectedTab, setTab] = useState('upcoming');
  const [data, setData] = useState(
    selectedTab === 'upcoming' ? upcoming : past
  );

  const updateQuantity = (index, add) => {
    let quantity = data[index].quantity;
    if (!add && quantity === minimumQuantity) {
      return;
    }

    setData(
      data.map((row, i) => {
        if (i === index) {
          return {
            ...row,
            quantity: add ? quantity + 1 : quantity - 1,
          };
        }
        return row;
      })
    );
  };

  return (
    <div>
      <div className={styles.individualCatalogues}>
        <h2>INDIVIDUAL CATALOGUES</h2>
        <Filters
          selectedTab={selectedTab}
          onClick={value => {
            setTab(value);
            setData(value === 'upcoming' ? upcoming : past);
          }}
        />
      </div>
      <div className={styles.cardSection}>
        {data.map((row, index) => (
          <div
            className={styles.card}
            key={index}
            style={{ backgroundImage: `url(${row.image})` }}
          >
            <div>
              <div>
                <h4>{row.title}</h4>
                <h4>${row.price}</h4>
                <div className={styles.count}>
                  <Button
                    type={styles.iconButton}
                    handleClick={() => updateQuantity(index, false)}
                  >
                    <MinusIcon color="#ffffff" />
                  </Button>
                  <h4>{row.quantity}</h4>
                  <Button
                    type={styles.iconButton}
                    handleClick={() => updateQuantity(index, true)}
                  >
                    <PlusIcon color="#ffffff" />
                  </Button>
                </div>
              </div>
              <Button type="app-transparent-white-button">Add to Cart</Button>
            </div>
            <div className={styles.overlay}></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Auctions;
